import React from "react"
import { useTranslation } from "react-i18next"
import { Container } from "react-bootstrap"

import styles from "./about.module.css"

export default function About() {
  const { t } = useTranslation()

  return (
    <Container name="about">
      <div className={styles.main}>
        <div>
          <Attribute title={"about.profesionality"} />
          <Attribute title={"about.experience"} />
          <Attribute title={"about.satisfaction"} />

          <div className={styles.aboutContainer}>
            <p>{t("about.firstParagraph")}</p>
            <p>{t("about.secondParagraph")}</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

function Attribute({ title }) {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.checker}>✓ </div>
      <div className={styles.title}>{t(title)} </div>
    </div>
  )
}
