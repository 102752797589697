import React from "react"
import Layout from "../components/Layout"

import Carousel from "../components/header/carousel/Carousel"
import About from "../components/about/About"

export default () => {
  return (
    <Layout>
      <Carousel />
      <About />
    </Layout>
  )
}
