import React from "react"
import { Carousel, Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./carousel.module.css"

export default function() {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query AssetsPhotos {
      allFile(
        filter: { relativeDirectory: { eq: "carousel" } }
        sort: { fields: childImageSharp___resolutions___originalName }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Carousel interval={3000}>
      {data.allFile.edges.map((edge, index) => {
        const imageSrc = edge.node.childImageSharp.fluid.src

        return (
          <Carousel.Item
            className={styles.item}
            style={{
              backgroundImage: `url(${imageSrc})`,
            }}
          >
            <Container>
              <Carousel.Caption>
                <h2 className={styles.caption}>{t(`carousel.${index + 1}`)}</h2>
              </Carousel.Caption>
            </Container>
          </Carousel.Item>
        )
      })}
    </Carousel>
  )
}
